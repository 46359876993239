<template>
  <v-container class="pt-0 px-0">
    <v-subheader class="text-h5 px-0">Termine</v-subheader>
    <v-badge left offset-y="11px" offset-x="-5px" color="blue" dot class="ml-4">Heute</v-badge><br />
    <v-badge left offset-y="11px" offset-x="-5px" color="red" dot class="ml-4">Fixierte&nbsp;Übung</v-badge><br />
    <v-badge left offset-y="11px" offset-x="-5px" color="grey" dot class="ml-4">Geplante&nbsp;Übung,&nbsp;noch&nbsp;nicht&nbsp;fixiert</v-badge><br />
    <v-card
      v-for="(item, i) in items"
      :key="i"
      color="white"
      :class="item._class+' '+item.heute"
      light
      class="termin pa-0 pb-1 mb-2"
      >
      <v-card-title class="zeitpunkt pt-1" v-html="zeitpunkt(item)"></v-card-title>
      <v-card-subtitle class="pb-0">{{ item._bezeichnung }}</v-card-subtitle>
      <v-card-text class="py-0" v-if="item._bemerkung" v-html="item._bemerkung.replaceAll('\n',' ')"></v-card-text>
      <v-card-text class="py-0" v-if="item.Ort">Ort:&nbsp;{{ item.Ort }}</v-card-text>
    </v-card>
    
      <v-snackbar
        v-model="snackbar"
        color="error"
        timeout="5000"
        bottom
         class="snackbar text-center"
        >
        {{ snackbar_text }}
        </v-snackbar>
  </v-container>
</template>

<script>

import axios from 'axios';

  export default {
    name: 'Termine',
    data: () => ({
        items:[],
        snackbar: false,
        snackbar_text: "Speichern erfolgreich",
    }),
    created: function(){
        axios({
              url: process.env.VUE_APP_APIURL+"termine/get",
              method: 'GET' })
          .then(result => {
              if (result.status=="200"){
                  this.snackbar = false;
                  this.items = result.data
              }else{
                  this.snackbar_text = "Datenabruf nicht möglich"
                  this.snackbar = true;
              }
          })
          .catch(() => {
                this.snackbar_text = "Datenabruf nicht möglich"
                this.snackbar = true;
          });
    },
    methods: {
      zeitpunkt: function(item){
        var d = item.Tag+'&nbsp;'+item.Datum;
        if (item.Zeit) d+= ',&nbsp;'+item.Zeit
        return d;
      }
    },
    computed:{
      
    }
  }
</script>
<style scoped>
.snackbar{
    text-align: center;
}

.termin{
  border-left-style: solid;
  border-left-width: 10px;
  padding-left:5px !important;
}
.termin>>>div{
  padding-left:0px !important;
}
.heute{
  border-left-color: #2196F3 !important;
}
.xx{
  border-left-color: #F44336 !important;
}
.yy{
  border-left-color: #9E9E9E !important;
}
</style>