<template>
  <settingsComp />
</template>

<script>

import settingsComp from '../components/settings.vue'

  export default {
    name: 'settings',
    components: {
        settingsComp,
    },
  }
</script>
