<template>
  <v-container class="pt-0 px-0">
    <v-subheader class="text-h5 px-0 mb-3">Schlauchprüfung {{ field }}
        <v-spacer></v-spacer>
        <v-btn class="mx-0" v-if="!hasKeyboardwedgeScanner" small fab dark :color="scanner_enabled ? 'secondary' : 'primary'" @click="scanner_enabled = !scanner_enabled">
            <v-icon dark>{{ scanner_enabled ? 'mdi-barcode-off' : 'mdi-barcode' }} </v-icon>
        </v-btn>
    </v-subheader>
    <v-text-field required clearable label="Schlauch" color="white" :background-color="field=='barcode' ? focusColor :'transparent'" outlined v-model="barcode" ref="barcode" @focus="field='barcode'"></v-text-field>
    <StreamBarcodeReader v-if="scanner_enabled" @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader>
    <div class="d-flex align-end flex-column">
        <v-checkbox hide-details :background-color="field=='sichtpruefung' ? focusColor:'transparent'" label="Sichtprüfung" v-model="sichtpruefung" ref="sichtpruefung" class="ml-4 mt-0 mb-4" color="white" @focus="field='sichtpruefung'"></v-checkbox>
        <v-checkbox hide-details :background-color="field=='druckpruefung' ? focusColor:'transparent'" label="Dichtprüfung" v-model="druckpruefung" ref="druckpruefung" class="ml-4 mt-0 mb-4" color="white" @focus="field='druckpruefung'"></v-checkbox>
    </div>
    <v-textarea clearable v-model="bemerkungen" color="white" hide-details :background-color="field=='bemerkungen' ? focusColor:'transparent'" outlined label="Bemerkung" ref="bemerkungen" @focus="field='bemerkungen'"></v-textarea>
    
    <v-btn class="my-3" block dark :color="this.barcode=='' ? 'secondary' : 'primary'" ref="save" @click="save">Speichern</v-btn>
    <!--<div style="background-color:blue; min-height:20px" class="mt-3">{{ xxx }}</div>-->
    
    <!--
    <v-btn class="mt-16" block dark  @click="processScan('SC123')">Scan: SC123</v-btn>
    <v-btn class="mt-3" block dark  @click="processScan('OK')">Scan: OK</v-btn>
    <v-btn class="mt-3" block dark  @click="processScan('weiter')">Scan: weiter</v-btn>
    <v-btn class="mt-3" block dark  @click="processScan('Bemerkung1')">Scan: Bemerkung1</v-btn>
    <v-btn class="mt-3" block dark  @click="processScan('__m:_neue__Laenge')">Scan: __m:_neue__Laenge</v-btn>
    -->
    


    <v-snackbar v-model="snackbar" :color="snackbar_color" timeout="2000" centered class="snackbar text-center text--center center-text center--text">{{ snackbar_text }}</v-snackbar>
  </v-container>
</template>

<script>

import { StreamBarcodeReader } from "vue-barcode-reader";
import beep from '@/mixins/beep';
import axios from 'axios';
import scanner from '@/mixins/scanner';
import onScan from 'onscan.js'

/*
document.addEventListener('scan', function(sScancode, iQuantity) {
    alert(iQuantity + 'x ' + sScancode); 
});
*/

  export default {
    name: 'Schlauchpflege',
    components:{
        StreamBarcodeReader
    },
    mixins: [beep,scanner],
    data: () => ({
        settings:{},
        field: "barcode",
        hasKeyboardwedgeScanner: false,
        barcode: "",
        sichtpruefung: false,
        druckpruefung: false,
        bemerkungen: "",
        snackbar: false,
        snackbar_color: "success",
        snackbar_text: "Speichern erfolgreich",
        scanner_enabled: false,
        prev_scan: '',
        num_same_scans: 0,
        focusColor: 'blue-grey darken-4',
        xxx: '',
        scanStack: [],
    }),
    mounted(){
        var s = localStorage.getItem("settings")
        if (s) this.settings =  JSON.parse(s);
        this.settings.avgTimeByChar = this.settings.avgTimeByChar || 60;
        this.settings.timeBeforeScanTest = this.settings.timeBeforeScanTest || 2;

        //  Barcodescanner
        this.init();
        this.$refs[this.field].focus()

        let suffixKeyCodes = scanner.getSuffix();
        if (suffixKeyCodes){
            if (window.scannerDetectionData !== undefined) onScan.detachFrom(window)
            
            onScan.attachTo(window,{
                suffixKeyCodes:suffixKeyCodes, // enter-key expected at the end of a scan
                captureEvents:true,
                minLength:3,
                avgTimeByChar: this.settings.avgTimeByChar,
                timeBeforeScanTest: this.settings.timeBeforeScanTest,
                preventDefault: true,
                stopPropagation: true,
                keyCodeMapper: function(oEvent) {

                    if (oEvent.which == 32) return " ";
                    /*
                    if (oEvent.which == 223) return "ß";
                    if (oEvent.which == 196) return "Ä";
                    if (oEvent.which == 220) return "Ü";
                    if (oEvent.which == 214) return "Ö";

                    if (oEvent.which == 228) return "ä";
                    if (oEvent.which == 252) return "ü";
                    if (oEvent.which == 246) return "ö";

                    if (oEvent.which == 229) return oEvent.key;
                    */
                    if (oEvent.which >= 186 && oEvent.which <= 192) {
                        return oEvent.key;
                    }
                    return onScan.decodeKeyEvent(oEvent);
                },
                onScan: (sCode) => { // Alternative to document.addEventListener('scan')
                    //scanner.removeFirstCharFromInputs()
                    //alert(sCode)
                    this.onScan(sCode)
                }
            });
        }
    },
    methods: {
        async init(){

        },
        focusNext(){
            if (typeof this.$refs[this.field].blur !== "undefined"){
                this.$refs[this.field].blur()
            }

            var sequence = ["barcode","sichtpruefung","druckpruefung","bemerkungen","save"]
            var idx = sequence.indexOf(this.field)
            if (idx<0) return false;
            if (idx+1>=sequence.length){
                idx = 0 //  Wieder von vorne
                //return false
            }else{
                idx++
            }

            this.field = sequence[idx]

            if (typeof this.$refs[this.field].focus !== "undefined"){
                this.$refs[this.field].focus()
            }
            if(typeof this.$refs[this.field].$el.focus !== "undefined"){
                this.$refs[this.field].$el.focus()
            }


            return true;
        },
        onScan(scandata){
            /*
            var focused = document.querySelector(":focus");
            if (typeof focused=='object' && this.field=="bemerkungen" && this.bemerkungen.length>0){
                // Hier müssen wir bei einem Scan den ersten Buchstaben löschen wenn dieser von der Timeout-Scannerengine kommt
                this.bemerkungen = this.bemerkungen.substr(0, this.bemerkungen.length - 1);  
            }
            */
            if (this.processScan(scandata)){
                this.beep(1,2500,"sine",50);
            }
        },
        onDecode (result) {
            if (result!=this.prev_scan){
                this.num_same_scans = 0;
            }else{
                this.num_same_scans++;
                if (this.num_same_scans>=this.settings.barcodepruefung){
                    if (this.processScan(result)){
                        this.beep(1,2500,"sine",50);
                        this.scanner_enabled = false;
                        setTimeout(() => {this.scanner_enabled = true},200);
                    }
                }else{
                    this.beep(0.5,3500,"sine",10);
                }
            }
            this.prev_scan = result;
        },
        onLoaded () {
            console.log("loaded")
        },
        processScan(barcode){      
            switch(this.field){
                case "barcode":
                    this.barcode = barcode
                    this.focusNext();
                    break;

                case "sichtpruefung":
                    if (barcode!="weiter") this.sichtpruefung = barcode.trim()=="OK" ? true : false
                    this.focusNext();
                    break;

                case "druckpruefung":
                    if (barcode!="weiter") this.druckpruefung = barcode.trim()=="OK" ? true : false
                    this.focusNext();
                    break;

                case "bemerkungen":
                    //var s = barcode
                    if (barcode.trim()=="OK" || barcode=="weiter" ){
                        this.focusNext();
                    }else{
                        if (barcode.substr(0,2)=="__"){
                            var b = this.bemerkungen
                            var n = ""  // Variable für Zahlen
                            for (var i = b.length - 1; i >= 0; i--) {   // Rückwärts durch die Zeichenkette der Bemerkung gehen
                                var c = b.substr(i,1);
                                console.log(i,c)
                                if (c=="\r" || c=="\n" || c==" "){  // CR / LF / SPACE wird weggeschnitten
                                    console.log(i,1)
                                    b = b.substring(0, i) + b.substring(i+1,b.length);
                                }else if (!c.match(/^[0-9]+$/g)){   //  wenn es keine Zahl mehr ist, rausspringen
                                    console.log(i,"break")
                                    break
                                }else{
                                    n = c + n   // die aktuelle Zahl kleben wir vor die bereits vorhandene, wir gehen ja rückwärts durch
                                    b = b.substring(0, i) + b.substring(i+1,b.length);  // ... und wir schneiden diese gleich weg
                                }
                            }
                            this.bemerkungen = b.trim() //  Das ist jetzt die neue Bemerkung ohne die Zahl
                            if (n==""){
                                barcode = barcode.substr(2)
                            }else{
                                barcode = parseInt(n) + barcode.substr(2)   // Die Zahl steht jetzt in n und wir kleben den Scan dran
                            }
                        }
                        if (this.bemerkungen.length>0 && this.bemerkungen.charAt(this.bemerkungen.length - 1)!="\n") this.bemerkungen+= "\r\n"  //  Zeilenumbruch dazubauen 
                        this.bemerkungen+= barcode.replaceAll("_"," ")  //  Barcode dranhängen
                    }
                    
                    break;

                case "save":
                    if (barcode.trim()=="OK") this.save();
                    this.focusNext();
                    break;
            }
        },
        save(){
            axios({ url: process.env.VUE_APP_APIURL+"schlauchpflege/save", data : { nummer: this.barcode, sichtpruefung: this.sichtpruefung, druckpruefung: this.druckpruefung, bemerkung: this.bemerkungen }, method: 'POST'})
            .then(result => {
                if (result.status=="200"){
                    this.snackbar_text = "OK"
                    this.snackbar_color = "success"
                    this.snackbar = true;
                    this.barcode = "";
                    this.sichtpruefung = false;
                    this.druckpruefung = false;
                    this.bemerkungen = "";
                    this.beep(1,2500,"sine",100);
                    this.beep(0,2500,"sine",50);
                    this.beep(1,2500,"sine",100);
                    this.beep(0,2500,"sine",50);
                    this.beep(1,2500,"sine",100);
                }else{
                    this.snackbar_text = "ERROR"
                    this.snackbar_color = "error"
                    this.snackbar = true;
                    this.beep(1,1000,"sine",1000);
                }
            })
            .catch(() => {
                    this.snackbar_text = "ERROR"
                    this.snackbar_color = "error"
                    this.snackbar = true;
                    this.beep(1,1000,"sine",1000);
            });
        },
    }
  }
</script>
<style>
.v-snack__content{
    text-align: center !important;
}
</style>