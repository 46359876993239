<template>
  <v-container class="pt-0 px-0">
    <v-subheader class="text-h5 px-0">Führerscheinkontrolle</v-subheader>

    <v-overlay absolute opacity="0.85" :value="scanner_enabled">
        <div class="d-flex justify-end">
            <v-btn v-if="scanner_enabled" class="mx-5" small fab dark color="secondary" @click="scanner_enabled = false">
                <v-icon dark>mdi-barcode-off</v-icon>
            </v-btn>
        </div>
        <StreamBarcodeReader v-if="scanner_enabled" @decode="onDecode"></StreamBarcodeReader>
    </v-overlay>

    <div class="d-flex">
        <v-autocomplete :items="mannschaft" v-model="selectedMannschaft" dense color="white" item-text="name" item-value="ID" label="Personalauswahl" placeholder="Tippen zum suchen" outlined @change="loadFuehrerscheinByID"></v-autocomplete>
        <v-btn class="mx-0 ml-3" small fab dark :color="scanner_enabled ? 'secondary' : 'primary'" @click="scanner_enabled = !scanner_enabled">
            <v-icon dark>{{ scanner_enabled ? 'mdi-barcode-off' : 'mdi-barcode' }} </v-icon>
        </v-btn>
    </div>
    <div v-if="data.name && data.ID">
        <v-row dense><v-col cols="5" class="font-weight-bold">Name:</v-col><v-col>{{ data.vorname + ' ' + data.name }}</v-col></v-row>
        <v-row dense><v-col cols="5" class="font-weight-bold">Nummer / Spind:</v-col><v-col>{{ data.nummer }} / {{ data.spind }}</v-col></v-row>
        <v-row dense><v-col cols="5" class="font-weight-bold">Führerschein:</v-col><v-col>{{ data.fuehrerschein }}</v-col></v-row>
        <v-row dense><v-col cols="5" class="font-weight-bold">letzte Kontrolle:</v-col><v-col>{{ data.letzteKontrolle }}</v-col></v-row>

        <v-divider class="my-3"></v-divider>
        <datefield class="mt-3" label="gültig bis" color="white" outlined placeholder="gültig bis" v-model="data.fuehrerschein_gueltig_bis"></datefield>
        <imagefield class="mt-3" truncate-length="15" maxsize="mediumblob" label="Führerschein Vorderseite" hide-details="auto" v-model="vorderseite" outlined></imagefield>
        <imagefield class="mt-3 mb-3" truncate-length="15" maxsize="mediumblob" label="Führerschein Rückseite" hide-details="auto" v-model="rueckseite" outlined></imagefield>
        <v-btn class="mx-0 mt-3" dark block color="primary" :disabled="save_disabled" @click="save" :loading="speichern">Speichern</v-btn>
        <v-btn class="mx-0 mt-3 mb-10" dark block color="secondary" :disabled="save_disabled" @click="resetForm">Abbrechen</v-btn>
    </div>
    <div v-else class="mt-16 text-center text-h5">
        Bitte Personal auswählen oder Barcode scannen
    </div>
    <v-snackbar
        v-model="snackbar"
        :color="snackbar_color"
        timeout="5000"
        bottom
         class="snackbar text-center"
        >
        {{ snackbar_text }}
        </v-snackbar>
  </v-container>
</template>

<script>

import { StreamBarcodeReader } from "vue-barcode-reader";
import beep from '@/mixins/beep';
import axios from 'axios';
import datefield from '@/components/Datefield';
import imagefield from '@/components/Imagefield';

  export default {
    name: 'Fuehrerscheinkontrolle',
    components:{
        StreamBarcodeReader,datefield,imagefield
    },
    mixins: [beep],
    data: () => ({
        settings:{},
        fuehrerscheinID: null,
        data:{},
        speichern: false,
        mannschaft:[],
        selectedMannschaft: null,

        snackbar: false,
        snackbar_color: "success",
        snackbar_text: "Speichern erfolgreich",
        scanner_enabled: false,
        prev_scan: '',
        num_same_scans: 0,

        vorderseite: '',
        rueckseite: '',
    }),
    mounted(){
        var s = localStorage.getItem("settings")
        if (s) this.settings =  JSON.parse(s);
        this.settings.barcodepruefung = this.settings.barcodepruefung || 2;
        this.loadMannschaft();
    },
    computed:{
        save_disabled:function(){
            return !this.data.name || !this.data.ID
        }
    },
    methods: {

        open_scanner(){
            this.prev_scan = "";
            this.scanner_enabled = true;  
        },
        loadMannschaft(){

            axios.get(process.env.VUE_APP_APIURL+"mannschaft/getMannschaftsliste")
            .then(result => {
                if (result.status=="200"){
                    this.mannschaft = result.data;
                }else{
                    this.snackbar_text = "Fehler beim laden der Mannschaftsauswahl"
                    this.snackbar_color = "error"
                    this.snackbar = true;
                }
            })
            .catch((e) => {
                    console.log(e);
                    this.snackbar_text = "Fehler beim laden der Mannschaftsauswahl"
                    this.snackbar_color = "error"
                    this.snackbar = true;
            });
        },
        loadFuehrerscheinByID(ID){
            if (ID) this.loadFuehrerschein(null,ID)
        },
        loadFuehrerschein(barcode,ID){
            this.resetForm();
            axios.get(process.env.VUE_APP_APIURL+"mannschaft/getFuehrerschein",{params : {personalBarcode:barcode,ID:ID}})
            .then(result => {
                if (result.status=="200"){
                    this.vorderseite = result.data.fuehrerschein_vorderseite
                    this.rueckseite = result.data.fuehrerschein_rueckseite
                    this.data = result.data;
                }else{
                    this.snackbar_text = "Fehler beim laden der Daten"
                    this.snackbar_color = "error"
                    this.snackbar = true;
                }
            })
            .catch((e) => {
                    console.log(e);
                    this.snackbar_text = "Fehler beim laden der Daten"
                    this.snackbar_color = "error"
                    this.snackbar = true;
            });
        },
        save(){
            this.speichern = true
            var vorderseite = this.vorderseite.substr(0,11)=='data:image/' ? null : this.vorderseite
            var rueckseite = this.rueckseite.substr(0,11)=='data:image/' ? null : this.rueckseite
            axios({
                url: process.env.VUE_APP_APIURL+"mannschaft/saveFuehrerschein",
                data : {
                    nummer:this.data.nummer,
                    ID:this.data.ID,
                    fuehrerschein_gueltig_bis:this.data.fuehrerschein_gueltig_bis,
                    fuehrerschein_vorderseite:vorderseite,
                    fuehrerschein_rueckseite:rueckseite,
                }, 
                method: 'POST' })
            .then(result => {
                if (result.status=="200"){
                    this.snackbar_text = "Speichern erfolgreich"
                    this.snackbar_color = "success"
                    this.snackbar = true;
                    this.resetForm();
                }else{
                    this.snackbar_text = "Speichern fehlerhaft"
                    this.snackbar_color = "error"
                    this.snackbar = true;
                }
                this.speichern = false;
            })
            .catch(() => {
                    this.snackbar_text = "Speichern fehlerhaft"
                    this.snackbar_color = "error"
                    this.snackbar = true;
                    this.speichern = false;
            });
        },
        resetForm(){
            this.selectedMannschaft = null
            this.data = {}
            this.vorderseite = ''
            this.rueckseite = ''
            this.fuehrerscheinID = null
        },
        onDecode (result) {
            if (result!=this.prev_scan){
                this.num_same_scans = 0;
            }else{
                this.num_same_scans++;
                if (this.num_same_scans>=this.settings.barcodepruefung){
                    this.fuehrerscheinID = result;
                    this.beep(1,2500,"square",50);
                    this.scanner_enabled = false;
                    this.loadFuehrerschein(this.fuehrerscheinID)
                }else{
                    this.beep(0.5,3500,"sine",10);
                }
            }
            this.prev_scan = result;
        }
    }
  }
</script>
<style scoped>
.snackbar{
    text-align: center;
}
.v-input__slot {
    align-items: right;
    justify-content:end
}
</style>