<template>
  <AlarmComp />
</template>

<script>

import AlarmComp from '@/components/Alarm.vue'

  export default {
    name: 'Alarm',
    components: {
      AlarmComp,
    },
  }
</script>
