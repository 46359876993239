

function removeFirstCharFromInputs(){
  if (document.querySelector(":focus")) {
    var val = ""
    var focused = document.querySelector(":focus");
    if (typeof focused!='object') return false
    if (focused.tagName == "INPUT") {
      if (focused.getAttribute("type") == "text") {
        val = focused.value.substr(0, focused.value.length - 1);
        focused.value = val;
      }
    }else if (focused.tagName == "TEXTAREA") {
        val = focused.value.substr(0, focused.value.length - 1);
        focused.value = val;
    }
  }
}

function getSuffix(){
  var s = localStorage.getItem("settings")
  if (s){
    this.settings =  JSON.parse(s);
    let suffix = []
    switch(this.settings.keyboardwedgescanner){
        case "CR":
            suffix = [13];
            break;
        case "LF":
            suffix = [10];
            break;
        case "CRLF":
            suffix = [10,13];
            break;
        case "Tab":
            suffix = [9];
            break;
    }
    return suffix;
  }else{
    return false
  }
}

export default {
  removeFirstCharFromInputs,
  getSuffix
};