<template>
  <v-container class="pt-0 px-0">
    <v-subheader class="text-h5 px-0">Kompressorlogbuch
        <v-spacer></v-spacer>
        <v-btn class="mx-0" small fab dark :color="scanner_enabled ? 'secondary' : 'primary'" @click="scanner_enabled = !scanner_enabled">
            <v-icon dark>{{ scanner_enabled ? 'mdi-barcode-off' : 'mdi-barcode' }} </v-icon>
        </v-btn>
    </v-subheader>
    <StreamBarcodeReader
        v-if="scanner_enabled"
        @decode="onDecode"
        @loaded="onLoaded"
    ></StreamBarcodeReader>
    <v-list dense>
        <v-subheader class="text-h6">
            Flaschen
            <v-spacer></v-spacer>
            <v-btn class="mx-0" dark color="primary" :disabled="items.length<=0" @click="save">
                Speichern
            </v-btn>
        </v-subheader>
        <v-list-item-group>
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
          >
            <v-list-item-action>
                <v-btn small fab dark color="danger" @click="remove(i)">
                    <v-icon color="red">mdi-delete</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-content>
              {{ item }}
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-snackbar
        v-model="snackbar"
        :color="snackbar_color"
        timeout="5000"
        bottom
         class="snackbar text-center"
        >
        {{ snackbar_text }}
        </v-snackbar>
  </v-container>
</template>

<script>

import { StreamBarcodeReader } from "vue-barcode-reader";
import beep from '@/mixins/beep';
import axios from 'axios';
import scanner from '@/mixins/scanner';

  export default {
    name: 'Kompressorlogbuch',
    components:{
        StreamBarcodeReader
    },
    mixins: [beep,scanner],
    data: () => ({
        settings:{},
        items:[],
        snackbar: false,
        snackbar_color: "success",
        snackbar_text: "Speichern erfolgreich",
        scanner_enabled: false,
        prev_scan: '',
        num_same_scans: 0,
    }),
    mounted(){
        var s = localStorage.getItem("settings")
        if (s) this.settings =  JSON.parse(s);
        this.settings.barcodepruefung = this.settings.barcodepruefung || 2;

        //  Barcodescanner
        scanner.setScannerMode(scanner.SCANNER_MODE_TIME);
        this.scanner.on("scan", this.onScan);
    },
    methods: {
        remove(itemIdx){
            this.items.splice(itemIdx,1);
        },
        onScan(scandata){
            if (this.items.indexOf(scandata)<0){
                this.items.push(scandata);
                this.beep(1,2500,"sine",50);
            }
        },
        save(){

            axios({
                url: process.env.VUE_APP_APIURL+"kompressorlogbuch/save",
                data : {
                    flaschen:this.items
                }, 
                method: 'POST' })
            .then(result => {
                if (result.status=="200"){
                    this.snackbar_text = "Speichern erfolgreich"
                    this.snackbar_color = "success"
                    this.snackbar = true;
                    this.items = []
                }else{
                    this.snackbar_text = "Speichern fehlerhaft"
                    this.snackbar_color = "error"
                    this.snackbar = true;
                }
            })
            .catch(() => {
                    this.snackbar_text = "Speichern fehlerhaft"
                    this.snackbar_color = "error"
                    this.snackbar = true;
            });
        },
        onDecode (result) {
            if (result!=this.prev_scan){
                this.num_same_scans = 0;
            }else{
                this.num_same_scans++;
                if (this.num_same_scans>=this.settings.barcodepruefung){
                    if (this.items.indexOf(result)<0){
                        this.items.push(result);
                        this.beep(1,2500,"sine",50);
                        this.scanner_enabled = false;
                        setTimeout(() => {this.scanner_enabled = true},200);
                    }
                }else{
                    this.beep(0.5,3500,"sine",10);
                }
            }
            this.prev_scan = result;
        },
        onLoaded () {
            console.log("loaded")
        }
    }
  }
</script>
<style scoped>
    .snackbar{
        text-align: center;
    }
</style>