<template>
  <v-container class="pa-0" fluid>
    <v-dialog v-model="dialog" max-width="85vw" >
        <img :src="image" alt="" width="100%" @click.stop="dialog=false">
    </v-dialog>
    <v-card
      class="mx-auto"
      :max-width="maxWidth"
      v-bind="$attrs"
      :dark="dark"
      :light="light"
    >
      <v-img v-if="hasImage" :src="image" class="white--text align-end" :class="hasImage ? 'notEmpty' : 'empty'" contain :width="width" :height="height" @click="zoom">
        <v-toolbar
            dense
            absolute 
            style="right:0px"
            v-show="image!='' && readonly!=true && disabled!=true"
          >
            <v-btn icon  color="error" @click.stop="clear">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
        </v-toolbar>
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              v-show="loading"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
      <v-card-actions v-if="readonly!=true && disabled!=true">
        <v-file-input
            ref="inputFile"
            @change="createBase64Image"
            @click:clear="clear"
            :clearable="false"
            :label="label"
            :dense="dense"
            :solo="solo"
            :placeholder="placeholder"
            :rules="rules"
            show-size
            :class="value=='' ? 'empty' : 'notEmpty'"
            truncate-length="30"
            accept="image/png, image/jpeg"
            prepend-icon="mdi-camera"
            :outlined="outlined"
            :color="color"
            :hide-details="hideDetails"
            :dark="dark"
            :light="light"
        >
        </v-file-input>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: 'Imagefield',
    inheritAttrs: false,
    data: () => ({
      dialog: false,
      loading: false,
      changed : false,
      initial_value : null,
    }),
    props:{
      'value':String,
      'color': String,
      'dark' : Boolean,
      'light' : Boolean,
      'hideDetails': String,
      'label':String,
      'readonly' : Boolean,
      'disabled' : Boolean,
      'dense':Boolean,
      'solo':Boolean,
      'placeholder':String,
      'outlined':Boolean,
      'maxsize':{
        type:[String,Number],
        default: "mediumblob"
      },
      'maxWidth':{
        type: String,
        default: "100%"
      },
      'height':{
        type:String,
        default: "200px"
      },
      'width':{
        type:String,
        default: "100vh"
      },
    },
    watch:{

    },
    computed: {
      rules(){
        const rules = []
        if (this.maxsize){
          var max = 0;
          var msg = '';

          if (String(this.maxsize).toLowerCase()=="tinyblob"){
            max = Math.pow(2,8) - 1;
          }else if (String(this.maxsize).toLowerCase()=="blob"){
            max = Math.pow(2,16) - 1;
          }else if (String(this.maxsize).toLowerCase()=="mediumblob"){
            max = Math.pow(2,24) - 1;
          }else if (String(this.maxsize).toLowerCase()=="longblob"){
            max = Math.pow(2,32) - 1;
          }else{
            max = parseInt(this.maxsize);
          }

          if (max>=(1024*1024)){
            msg = 'Dateigröße muss unter ' + Math.floor(max / (1024*1024)) + ' MB sein!'
          }else if (max>=1024){
            msg = 'Dateigröße muss unter ' + Math.floor(max / 1024) + ' kB sein!'
          }else{
            msg = 'Dateigröße muss unter ' + max + ' Bytes sein!'
          }
          const rule = v => !v || v.size < max || msg
          rules.push(rule)
        }
        return rules;
      },
      hasImage(){
        var v = this.changed ? this.value : this.initial_value;
        if (!v || v=="") return false
        if (this.value=="deleted") return false
        return true;
      },
      image(){
        var v = this.changed ? this.value : this.initial_value;
        if (!v || v==""){
            return "";
        }else if (v.substr(0,11)=="data:image/"){
            return v;
        }else if (v.substr(0,4)=="/9j/"){
            return "data:image/jpeg;base64," + v;
        }else{
            return "data:image/png;base64," + v;
        }
      }
    },
    created() {
      this.initial_value = this.value;
      this.$emit('input', '')
    },
    methods: {
      zoom(){
        this.dialog = this.hasImage ? true : false;
      },
      clear(){
        this.$emit('input', 'deleted')
        this.$refs.inputFile.$el.getElementsByTagName("INPUT")[0].value="";
        this.$refs.inputFile.reset();
        this.changed = true;
      },
      createBase64Image(fileObject){
        if (!fileObject){
          this.loading = false;
          return false;
        }
        const r = new FileReader();
        if (fileObject) this.loading = true;
        r.onload = (e) => {
          this.changed = true;
          this.$emit('input', e.target.result.split(',')[1])
          this.loading = false;
        }
        r.readAsDataURL(fileObject)
      }
    },
  }
</script>

<style scoped>
  .v-image.notEmpty{
    cursor:zoom-in
  }
  .v-dialog__content--active{
    cursor:zoom-out
  }
</style>