<template>
  <KompressorlogbuchComp />
</template>

<script>

import KompressorlogbuchComp from '../components/Kompressorlogbuch.vue'

  export default {
    name: 'kompressorlogbuch',
    components: {
        KompressorlogbuchComp,
    },
  }
</script>
