<template>
  <v-container class="pt-0 px-0">
    <v-subheader class="text-h5 px-0 mb-3">Schlauchannahme
        <v-spacer></v-spacer>
        <v-btn class="mx-0" v-if="!hasKeyboardwedgeScanner" small fab dark :color="scanner_enabled ? 'secondary' : 'primary'" @click="scanner_enabled = !scanner_enabled">
            <v-icon dark>{{ scanner_enabled ? 'mdi-barcode-off' : 'mdi-barcode' }} </v-icon>
        </v-btn>
    </v-subheader>
    <v-autocomplete required :items="anliefererliste" label="Anlieferer" outlined v-model="anlieferer"></v-autocomplete>
    <v-text-field required label="Schlauch" outlined v-model="barcode"></v-text-field>
    <StreamBarcodeReader v-if="scanner_enabled" @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader>
    <v-btn class="mx-0" block dark :color="this.barcode=='' ? 'secondary' : 'primary'" @click="save">Speichern</v-btn>
    <v-snackbar v-model="snackbar" :color="snackbar_color" timeout="2000" centered class="snackbar text-center text--center center-text center--text">{{ snackbar_text }}</v-snackbar>
  </v-container>
</template>

<script>

import { StreamBarcodeReader } from "vue-barcode-reader";
import beep from '@/mixins/beep';
import axios from 'axios';
import scanner from '@/mixins/scanner';
import onScan from 'onscan.js'

  export default {
    name: 'Schlauchannahme',
    components:{
        StreamBarcodeReader
    },
    mixins: [beep,scanner],
    data: () => ({
        settings:{},
        anliefererliste: [],
        anlieferer: "",
        barcode: "",
        snackbar: false,
        snackbar_color: "success",
        snackbar_text: "Speichern erfolgreich",
        scanner_enabled: false,
        prev_scan: '',
        num_same_scans: 0,
    }),
    mounted(){
        var s = localStorage.getItem("settings")
        if (s) this.settings =  JSON.parse(s);
        this.settings.barcodepruefung = this.settings.barcodepruefung || 2;
        this.settings.keyboardwedgescanner = this.settings.keyboardwedgescanner || "";
        this.settings.avgTimeByChar = this.settings.avgTimeByChar || 60;
        this.settings.timeBeforeScanTest = this.settings.timeBeforeScanTest || 2;
        this.init();

        //  Barcodescanner
        let suffixKeyCodes = scanner.getSuffix();
        if (suffixKeyCodes){
            if (window.scannerDetectionData !== undefined) onScan.detachFrom(window)
            onScan.attachTo(window,{
                suffixKeyCodes: suffixKeyCodes, // enter-key expected at the end of a scan
                captureEvents:true,
                minLength:3,
                avgTimeByChar: this.settings.avgTimeByChar,
                timeBeforeScanTest: this.settings.timeBeforeScanTest,
                onScan: (sCode) => { // Alternative to document.addEventListener('scan')
                    this.snackbar_text = sCode
                    this.snackbar_color = "success"
                    this.snackbar = true
                    this.onScan(sCode)
                },
                onScanError:() => {
                    
                    if (this.barcode.length>=3){
                        if (this.save()){
                            this.snackbar_text = this.barcode
                            this.snackbar_color = "success"
                            this.snackbar = true
                            this.beep(1,2500,"sine",50);
                        }
                    }
                    /*
                    this.snackbar_text = "Fehler beim Scannen: "+oDebug.message
                    this.snackbar_color = "error"
                    this.snackbar = true;
                    this.logmsg(JSON.stringify(oDebug),10)
                    this.logmsg('Suffix Key-Codes: '+JSON.stringify(suffixKeyCodes),10)
                    */
                }
              
            });
        }else{
            this.snackbar_text = "Kein Keyboardwedge-Scanner eingestellt"
            this.snackbar_color = "warning"
            this.snackbar = true;
        }
        
    },
    methods: {
        async init(){
            await axios({
                url: process.env.VUE_APP_APIURL+"schlauchannahme/getAnlieferer",method: 'GET' })
            .then(result => {
                if (result.status=="200"){
                    this.anliefererliste = result.data
                }
            })
            .catch(() => {
                    this.snackbar_text = "Fehler beim Initialisieren"
                    this.snackbar_color = "error"
                    this.snackbar = true;
            });
        },
        logmsg(message,prio){
            axios({ url: process.env.VUE_APP_APIURL+"system/logmsg", data : { message: message, prio: prio }, method: 'POST'})
        },
        onScan(scandata){
            this.barcode = scandata
            if (this.save()){
                this.beep(1,2500,"sine",50);
            }
        },
        onDecode (result) {
            if (result!=this.prev_scan){
                this.num_same_scans = 0;
            }else{
                this.num_same_scans++;
                if (this.num_same_scans>=this.settings.barcodepruefung){
                    this.barcode = result
                    if (this.save()){
                        this.beep(1,2500,"sine",50);
                        this.scanner_enabled = false;
                        setTimeout(() => {this.scanner_enabled = true},200);
                    }
                }else{
                    this.beep(0.5,3500,"sine",10);
                }
            }
            this.prev_scan = result;
        },
        onLoaded () {
            console.log("loaded")
        },
        save(){
            axios({ url: process.env.VUE_APP_APIURL+"schlauchannahme/save", data : { nummer: this.barcode, anlieferer: this.anlieferer }, method: 'POST'})
            .then(result => {
                if (result.status=="200"){
                    this.snackbar_text = "OK"
                    this.snackbar_color = "success"
                    this.snackbar = true;
                    this.barcode = "";
                }else{
                    this.snackbar_text = "ERROR"
                    this.snackbar_color = "error"
                    this.snackbar = true;
                }
            })
            .catch(() => {
                    this.snackbar_text = "ERROR"
                    this.snackbar_color = "error"
                    this.snackbar = true;
            });
        },
    }
  }
</script>
<style>
.v-snack__content{
    text-align: center !important;
}
</style>