<template>
  <v-container class="pt-0 px-0">
    <v-subheader class="text-h5 px-0">Geräte / Ausrüstung
        <v-spacer></v-spacer>
        <v-btn class="mx-0" small fab dark :color="scanner_enabled ? 'secondary' : 'primary'" @click="scanner_enabled = !scanner_enabled">
            <v-icon dark>{{ scanner_enabled ? 'mdi-barcode-off' : 'mdi-barcode' }} </v-icon>
        </v-btn>
        <!--<v-btn @click="test">
            Test
        </v-btn>-->
    </v-subheader>
    <StreamBarcodeReader v-if="scanner_enabled" @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader>
    <div v-if="itemSelected==false && items.length==0" class="mt-16 text-center text-h5">
        Zum auswählen
    </div>
    <div v-if="itemSelected==false && items.length==0" class="mt-0 text-center text-h5">
        Barcode scannen
    </div>
    <v-list dense v-else-if="itemSelected==false && items.length>0">
        <v-subheader class="text-h6">
            Gerät auswählen
        </v-subheader>
        <v-list-item-group>
          <v-list-item v-for="(item, i) in items" :key="i" three-line>
            <v-list-item-content>
              <v-list-item-subtitle>{{ item.i_typ }}</v-list-item-subtitle>
              <v-list-item-title>{{ item.hersteller }} / {{ item.typ }}</v-list-item-title>
              <div>{{ item.nummer }}</div>
            </v-list-item-content>
            <v-list-item-action>
                <v-btn small fab dark color="primary" @click="select(i)" :loading="item.loading">
                    <v-icon>mdi-arrow-right-circle</v-icon>
                </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
    </v-list>
    <div v-if="itemSelected" class="pt-3">
        <v-toolbar height="100px" color="primary">
            <v-toolbar-title>
                <div class="text-h5">{{ item.i_typ }}</div>
                <div class="text-h6">{{ item.hersteller }} / {{ item.typ }}</div>
                <div class="text-h6">Nummer: {{ item.nummer }}</div>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn small fab color="white" class="primary--text" @click="item={}">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
        </v-toolbar>
        <v-list dense>
            <v-subheader class="text-h6">
                Wartung auswählen
            </v-subheader>
            <v-list-item-group v-for="(wartungsteil, i) in item.wartungsteile" :key="i">
                <v-list-item two-line :style="'border-left:10px solid '+(wartungsteil.color || 'transparent')">
                    <v-list-item-content>
                        <v-list-item-title>{{ wartungsteil.wartungsart }}</v-list-item-title>
                        <v-list-item-title>{{ wartungsteil.bezeichnung }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-content>
                        <v-list-item-subtitle>Letzte Wartung: {{ wartungsteil.letzte_wartung }}</v-list-item-subtitle>
                        <v-list-item-subtitle :class="wartungsteil.color+'--text'">Wartung fällig: {{ wartungsteil.wartung_faellig }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-checkbox v-model="wartungsteil.wartung_durchgefuehrt" style="display:inline-block" class="ml-4 mt-0" color="white"></v-checkbox>
                    </v-list-item-action>                
                </v-list-item>
                <v-list-item v-if="wartungsteil.wartung_durchgefuehrt">
                    <v-list-item-content>
                        <v-text-field class="mb-2" label="Messwert" v-model="wartungsteil.wartung_messwert" required color="white" dense hide-details="auto" outlined autocomplete="false" append-outer-icon="mdi-thumb-up" @click:append-outer="toggleMesswert(wartungsteil)"></v-text-field>
                        <v-text-field class="mb-2" label="Kosten" v-model="wartungsteil.wartung_wartungskosten" type="number" append-icon="mdi-currency-eur" dense hide-details="auto" required color="white" outlined autocomplete="false"></v-text-field>
                        <v-text-field label="Wartungsursache" v-model="wartungsteil.wartung_wartungsursache" dense hide-details="auto" required color="white" outlined autocomplete="false"></v-text-field>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
            <v-textarea v-model="bemerkung" class="mt-3 mb-2" hide-details="auto" outlined label="Bemerkung" color="white" value=""></v-textarea>   
            <v-btn class="mx-0 mb-5" dark block color="primary" @click="save">Speichern</v-btn> 
            <v-sheet v-html="item.datasheet" color="white" class="mt-3 mb-10 pa-3 black--text" v-if="item.datasheet"></v-sheet>
        </v-list>
    </div>
    <v-snackbar v-model="snackbar" :color="snackbar_color" timeout="5000" bottom class="snackbar text-center">{{ snackbar_text }}</v-snackbar>
  </v-container>
</template>

<script>

import { StreamBarcodeReader } from "vue-barcode-reader";
import beep from '@/mixins/beep';
import axios from 'axios';
import scanner from '@/mixins/scanner';
import onScan from 'onscan.js'

  export default {
    name: 'Geraete',
    components:{
        StreamBarcodeReader
    },
    mixins: [beep,scanner],
    data: () => ({
        settings:{},
        item:{},
        wartung:[],
        bemerkung: "",
        hasKeyboardwedgeScanner: false,
        items:[],
        snackbar: false,
        snackbar_color: "success",
        snackbar_text: "Speichern erfolgreich",
        scanner_enabled: false,
        prev_scan: '',
        num_same_scans: 0,
    }),
    mounted(){
        var s = localStorage.getItem("settings")
        if (s) this.settings =  JSON.parse(s);
        this.settings.barcodepruefung = this.settings.barcodepruefung || 2;

        //  Barcodescanner
        let suffixKeyCodes = scanner.getSuffix();
        if (suffixKeyCodes){
            if (window.scannerDetectionData !== undefined) onScan.detachFrom(window)

            onScan.attachTo(window,{
                suffixKeyCodes: suffixKeyCodes, // enter-key expected at the end of a scan
                captureEvents:true,
                minLength:3,
                avgTimeByChar: 60,
                timeBeforeScanTest: 200,
                preventDefault: true,
                stopPropagation: true,
                keyCodeMapper: function(oEvent) {

                    if (oEvent.which == 32) return " ";
                    /*
                    if (oEvent.which == 223) return "ß";
                    if (oEvent.which == 196) return "Ä";
                    if (oEvent.which == 220) return "Ü";
                    if (oEvent.which == 214) return "Ö";

                    if (oEvent.which == 228) return "ä";
                    if (oEvent.which == 252) return "ü";
                    if (oEvent.which == 246) return "ö";

                    if (oEvent.which == 229) return oEvent.key;
                    */
                    if (oEvent.which >= 186 && oEvent.which <= 192) {
                        return oEvent.key;
                    }
                    return onScan.decodeKeyEvent(oEvent);
                },
                onScan: (sCode) => { // Alternative to document.addEventListener('scan')
                    //scanner.removeFirstCharFromInputs()
                    //alert(sCode)
                    this.onScan(sCode)
                }
            });
        }

        //scanner.setScannerMode(scanner.SCANNER_MODE_TIME);
        //this.scanner.on("scan", this.onScan);
    },
    computed:{
        itemSelected(){
            return Object.keys(this.item).length>0
        }
    },
    methods: {
        async onScan(scandata){
            this.item = {}
            await axios({
                url: process.env.VUE_APP_APIURL+"geraete/get?barcode="+scandata,
                method: 'GET' })
            .then(result => {
                if (result.status=="200"){
                    this.items = result.data
                    this.beep(1,2500,"sine",50);
                }else{
                    this.snackbar_text = "Abruf fehlerhaft"
                    this.snackbar_color = "error"
                    this.snackbar = true;
                    this.beep(0.5,3500,"sine",10);
                }
            })
            .catch(() => {
                    this.snackbar_text = "Abruf fehlerhaft"
                    this.snackbar_color = "error"
                    this.snackbar = true;
                    this.beep(0.5,3500,"sine",10);
            });
        },
        setLoadingOff(){
            for(var i in this.items){
                this.$set(this.items[i],'loading',false)
            }
        },
        select(itemIdx){
            this.setLoadingOff()
            this.item = this.items[itemIdx];
            this.$set(this.items[itemIdx],'loading',true)
            this.get_geraeteinfo(this.items[itemIdx].i_typ,this.items[itemIdx].nummer,this.items[itemIdx].ID)
            this.item.loading = false;
        },
        toggleMesswert(wartungsteil){
            if (wartungsteil.wartung_messwert=='in Ordnung'){
                wartungsteil.wartung_messwert = ''
            }else{
                wartungsteil.wartung_messwert = 'in Ordnung'
            }
        },
        save(){
            var wt = []
            for(var w of this.item.wartungsteile){
                if (w.wartung_durchgefuehrt==true) wt.push(w)
            }
            axios({
                url: process.env.VUE_APP_APIURL+"geraete/save",
                data : {
                    ID:this.item.ID,
                    hersteller:this.item.hersteller,
                    i_typ:this.item.i_typ,
                    nummer:this.item.nummer,
                    typ:this.item.typ,
                    wartungsteile: wt,
                    bemerkung: this.bemerkung
                }, 
                method: 'POST' })
            .then(result => {
                if (result.status=="200"){
                    this.snackbar_text = "Speichern erfolgreich"
                    this.snackbar_color = "success"
                    this.snackbar = true;
                    this.item = {}
                    this.items = []
                }else{
                    this.snackbar_text = "Speichern fehlerhaft"
                    this.snackbar_color = "error"
                    this.snackbar = true;
                }
            })
            .catch(() => {
                    this.snackbar_text = "Speichern fehlerhaft"
                    this.snackbar_color = "error"
                    this.snackbar = true;
            });
        },
        test(){
            this.onScan("Barcode")
        },
        get_geraeteinfo(i_typ,nummer,geraet_id){
            axios({
                url: process.env.VUE_APP_APIURL+"geraete/get_geraeteinfo?i_typ="+i_typ+"&nummer="+nummer+"&ID="+geraet_id,
                method: 'GET' })
            .then(result => {
                if (result.status=="200"){
                    this.$set(this.item,"datasheet",result.data.datasheet)
                    this.$set(this.item,"wartung",result.data.wartung)
                    for(var idx in result.data.wartungsteile){
                        result.data.wartungsteile[idx]["wartung_durchgefuehrt"] = false;
                        result.data.wartungsteile[idx]["wartung_messwert"] = "";
                        result.data.wartungsteile[idx]["wartung_wartungsursache"] = "";
                        result.data.wartungsteile[idx]["wartung_wartungskosten"] = "";
                    }
                    this.$set(this.item,"wartungsteile",result.data.wartungsteile)
                    this.beep(1,2500,"sine",50);
                }else{
                    this.snackbar_text = "Problem beim Geräteabruf"
                    this.snackbar_color = "error"
                    this.snackbar = true;
                    this.setLoadingOff()
                    this.beep(0.5,3500,"sine",10);
                }
            })
            .catch(() => {
                this.snackbar_text = "Geräteabruf fehlerhaft"
                this.snackbar_color = "error"
                this.snackbar = true;
                this.setLoadingOff()
                this.beep(0.5,3500,"sine",10);
            });
        },
        onDecode (result) {
            if (result!=this.prev_scan){
                this.num_same_scans = 0;
            }else{
                this.num_same_scans++;
                if (this.num_same_scans>=this.settings.barcodepruefung){
                    this.item = {}
                    axios({
                        url: process.env.VUE_APP_APIURL+"geraete/get?barcode="+result,
                        method: 'GET' })
                    .then(result => {
                        this.num_same_scans = 0;
                        if (result.status=="200"){
                            this.items = result.data
                            this.beep(1,2500,"sine",50);
                            this.scanner_enabled = false;
                        }else{
                            this.snackbar_text = "Abruf fehlerhaft"
                            this.snackbar_color = "error"
                            this.snackbar = true;
                        }
                    })
                    .catch(() => {
                            this.snackbar_text = "Abruf fehlerhaft"
                            this.snackbar_color = "error"
                            this.snackbar = true;
                    });
                }else{
                    this.beep(0.5,3500,"sine",10);
                }
            }
            this.prev_scan = result;
        },
        onLoaded () {
            console.log("loaded")
        }
    }
  }
</script>
<style scoped>
    .snackbar{
        text-align: center;
    }
</style>