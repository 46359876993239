<template>
  <v-container class="pt-0 px-0">
    <v-subheader class="text-h5 px-0">Einstellungen</v-subheader>
    
    <v-select class="mt-3" :items="barcodepruefungItems" v-model="settings.barcodepruefung" label="Barcodeprüfung" hint="Genauigkeit des Barcodesscans" outlined item-text="text" item-value="val" color="white" item-color="grey lighten-5" persistent-hint></v-select>

    <v-select class="mt-3" :items="keyboardwedgesettings" v-model="settings.keyboardwedgescanner" label="Keyboard-Wedge Scanner" hint="Scanner mit Tastatursimulation (Spezielle Geräte)" outlined item-text="text" item-value="val" color="white" item-color="grey lighten-5" persistent-hint></v-select>

    <div class="d-flex mt-3">
        <v-text-field type="number" v-model="settings.avgTimeByChar" label="Durchschnittszeit per Buchstabe" placeholder="60" outlined color="white" persistent-hint suffix="ms"></v-text-field>
        <v-spacer></v-spacer>
        <v-text-field type="number" v-model="settings.timeBeforeScanTest" label="Zeit vor Scantest" placeholder="200" outlined color="white" persistent-hint suffix="ms"></v-text-field>
    </div>
    <v-textarea auto-grow label="Geräteerkennung" class="mt-3" :value="device" outlined readonly></v-textarea>
    <v-btn class="mx-0 mt-5 mb3" dark block color="primary" @click="save">Speichern</v-btn>
    <v-snackbar v-model="snackbar" :color="snackbar_color" timeout="5000" bottom class="snackbar text-center">{{ snackbar_text }}</v-snackbar>
  </v-container>
</template>

<script>


  export default {
    name: 'settings',
    data: () => ({
        settings:{
            barcodepruefung: 2,
            keyboardwedgescanner: "",
            avgTimeByChar: 60,
            timeBeforeScanTest: 200,
        },
        barcodepruefungItems:[
            {val: 1, text: "keine verbesserte Genauigkeit"},
            {val: 2, text: "gering verbesserte Genauigkeit"},
            {val: 3, text: "normale Genauigkeit"},
            {val: 4, text: "hohe Genauigkeit"},
        ],
        keyboardwedgesettings:[
            {val: "", text: "Kein Scanner mit Tastatursimulation"},
            {val: "CR", text: "Scanner mit Endezeichen CR"},
            {val: "LF", text: "Scanner mit Endezeichen LF"},
            {val: "CRLF", text: "Scanner mit Endezeichen CR/LF"},
            {val: "Tab", text: "Scanner mit Endezeichen Tab"},
        ],
        snackbar: false,
        device: false,
        snackbar_color: "success",
        snackbar_text: "Speichern erfolgreich",
    }),
    mounted(){
        var s = localStorage.getItem("settings")
        if (s) this.settings =  JSON.parse(s);
        this.device = window.navigator.userAgent;
    },
    methods: {
        save(){
            try{
                localStorage.setItem("settings",JSON.stringify(this.settings));
                this.snackbar_color = "success";
                this.snackbar_text = "Speichern erfolgreich";
            }catch(e){
                this.snackbar_color = "danger";
                this.snackbar_text = "Speichern fehlerhaft";
                if (e.message) this.snackbar_text+= ': '+e.message
            }
            this.snackbar = true;
        },
    }
  }
</script>
<style scoped>
    .snackbar{
        text-align: center;
    }
    .v-input__slot {
    align-items: right;
    justify-content:end
    }
</style>