<template>
    <v-snackbar v-if="showInstallMessage" :value="showInstallMessage" class="InstallMessage" color="grey lighten-3" absolute light app multi-line :timeout="-1">
     Zu installieren: Drücken sie auf <svg class="ios-share" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M12,1L8,5H11V14H13V5H16M18,23H6C4.89,23 4,22.1 4,21V9A2,2 0 0,1 6,7H9V9H6V21H18V9H15V7H18A2,2 0 0,1 20,9V21A2,2 0 0,1 18,23Z" /></svg> und anschließend auf <svg class="ios-add-to-home" style="width:24px;height:24px" viewBox="0 0 24 24">
            <path d="M19,19V5H5V19H19M19,3A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5C3,3.89 3.9,3 5,3H19M11,7H13V11H17V13H13V17H11V13H7V11H11V7Z" />
        </svg> "Zum Home-Bildschirm"
        <!--<v-btn fab icon v-bind="attrs" @click="showInstallMessage = false">
          <v-icon dark>mdi-window-close</v-icon>
        </v-btn>
        -->
    </v-snackbar>
    <v-snackbar v-else-if="updateExists" :value="updateExists" class="UpdateMessage" color="grey lighten-3" absolute light app multi-line :timeout="-1">
        Ein Update ist verfügbar
        <v-btn color="primary" class="ml-5" @click="refreshApp">
            Installieren 
        </v-btn>
    </v-snackbar>
</template>

<script>
export default { 
    name: "pwa",
    data() {
      return {
        showInstallMessage: false,
        // refresh variables
        refreshing: false,
        registration: null,
        updateExists: false,
      }
    },
    created() {
      /*
      if (this.isIos && !this.isInStandaloneMode) {
          this.showInstallMessage = true;
      }
      */
      // Listen for our custom event from the SW registration
      document.addEventListener('swUpdated', (e) => { 
        console.log('swUpdated',e);  
        this.updateAvailable(e)
      }, { once: true })
  
      // Prevent multiple refreshes
      if (navigator.serviceWorker){
        navigator.serviceWorker.addEventListener('controllerchange', () => {
          console.log('controllerchange called')
          if (this.refreshing) return
          this.refreshing = true
          // Here the actual reload of the page occurs
          window.location.reload()
        })
      }
    },
    mounted() {
      this.showInstallMessage = this.showInstall;
    },
    methods: {
      // Store the SW registration so we can send it a message
      // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
      // To alert the user there is an update they need to refresh for
      updateAvailable(event) {
          console.log('updateAvailable called')
        this.registration = event.detail
        this.updateExists = true
      },
  
      // Called when the user accepts the update
      refreshApp() {
        console.log('refreshApp called')
        this.updateExists = false
        // Make sure we only send a 'skip waiting' message if the SW is waiting
        if (!this.registration || !this.registration.waiting) return
        // send message to SW to skip the waiting and activate the new SW
        this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      },
    },
    computed:{
      showInstall(){
        if (this.isIos){
          if (window.navigator && !window.navigator.standalone) return true;
        }
        return false;
      },
      isIos(){
          const userAgent = window.navigator.userAgent.toLowerCase();
          return /iphone|ipad|ipod/.test( userAgent );
      },
    }
  }
</script>

<style scoped>
.InstallMessage,
.UpdateMessage{
  z-index: 7;
}
.InstallMessage>>>.v-snack__content{
  font-size: 0.8rem !important;
}
.ios-add-to-home,
.ios-share{
    position: relative;
}
.ios-share{
    top: 3px;
}
.ios-add-to-home{
    top: 5px;
}
svg {
  fill: currentColor;
}

</style>