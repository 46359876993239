<template>
    <v-container class="d-flex justify-space-around">

                <v-card width="400px">
                    <v-img
                        :src="require('../assets/logo.jpg')"
                        width="100%"
                        >
                    </v-img>
                    <v-card-title>Login</v-card-title>
                    <v-card-text class="pt-4">
                        <div>
                            <v-form v-model="valid" ref="form">
                                <v-text-field
                                    label="Benutzername"
                                    v-model="user"
                                    required
                                    color="white"
                                    outlined
                                    autocomplete="false"
                                ></v-text-field>
                                <v-text-field
                                    label="Kennwort"
                                    v-model="pwd"
                                    :append-icon="e1 ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                                    @click:append="() => (e1 = !e1)"
                                    :type="e1 ? 'password' : 'text'"
                                    autocomplete="false"
                                    color="white"
                                    outlined
                                    required
                                ></v-text-field>
                                <v-layout justify-end>
                                    <v-btn @click="login" color="primary" :class=" { 'white--text' : valid, disabled: !valid }">Login</v-btn>
                                </v-layout>
                            </v-form>
                        </div>
                    </v-card-text>
                </v-card>

    </v-container>
</template>

<script>

    import { AUTH_REQUEST } from "@/store/actions/auth";

  export default {
    name: 'Login',
    data () {
          return {
            valid: false,
            e1: true,
            pwd: '',
            user: '',
          }
        },
    methods: {
        login: function () {
            const { user, pwd } = this
            this.$store.dispatch(AUTH_REQUEST, { user, pwd }).then(() => {
                this.$router.push('/')
            })
        },
        clear () {
            this.$refs.form.reset()
          }
        },
  }
</script>
