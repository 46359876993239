<template>
    <div>
        <div v-if="!faxGeladen" id="faxe">
            <v-list three-line class="pa-0">
                <template  v-for="(fax,index) in faxe">
                    <v-list-item v-on:click="openAlarm(fax)" :key="fax.ID" :class="getClass(fax)">
                        <v-list-item-content>
                            <v-list-item-title>{{ fax.Stichwort }} - {{ fax.Schlagwort }}</v-list-item-title>
                            <v-list-item-subtitle>{{ fax.Strasse }} {{ fax.Haus_Nr }}</v-list-item-subtitle>
                            <v-list-item-subtitle>{{ fax.PLZ }} {{ fax.Ortsname }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-list-item-action-text>{{ fax.AlarmDatum }}<br />{{ fax.Alarm_Zeit }}</v-list-item-action-text>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider v-if="index < faxe.length-1" :key="'key_'+index"></v-divider>
                </template>
            </v-list>
        </div>
        <div v-else-if="!karte" id="fax">
            <div class="d-flex">
                <h1>Alarmfax</h1>
                <v-spacer></v-spacer>
                <v-btn color="primary" style="position:fixed;top:13px;right:12px;z-index:99" @click="fax={}"><v-icon>mdi-reply</v-icon></v-btn>
            </div>
            <h4 class="mb-0">{{ fax.AlarmDatum }}, {{ fax.Alarm_Zeit }}</h4>
            <h2 class="mb-2">{{ fax.Stichwort }} / {{ fax.Schlagwort }}</h2>
            <h4 class="mb-0">Bemerkung:</h4>
            <div>{{ fax.Bemerkung }}</div>
            <div class="d-flex" v-if="not_empty(fax.Mitteiler)">
                <h4 class="mr-3 pt-1">Mitteiler:</h4>
                <div>{{ fax.Mitteiler }}</div>
            </div>
            <div class="d-flex">
                <div class="">
                    <h4 class="mr-3 pt-1">Einsatzort:</h4>
                    <v-btn class="mb-2" v-if="fax.nav_url" color="primary" :href="fax.nav_url" :target="fax.nav_target"><v-icon>mdi-car</v-icon></v-btn><br />
                    <v-btn color="primary" @click="show_karte"><v-icon>mdi-map</v-icon></v-btn>
                </div>
                <div class="pt-1">
                    <div v-if="not_empty(fax.Objekt)">{{ fax.Objekt }}</div>
                    <div>{{ fax.Strasse }} {{ fax.Haus_Nr }} {{ fax.Haus_Nr_Zusatz }}</div>
                    <div>{{ fax.PLZ }} {{ fax.Ortsname }}</div>
                    <div v-if="not_empty(fax.Ortsteil)">Ortsteil: {{ fax.Ortsteil }}</div>
                    <div v-if="not_empty(fax.Abschnitt)">Abschnitt: {{ fax.Abschnitt }}</div>
                    <div v-if="not_empty(fax.Kreuzung)">Kreuzung: {{ fax.Kreuzung }}</div>
                    <div v-if="not_empty(fax.Station)">Station: {{ fax.Station }}</div>
                </div>
            </div>
            <h4 class="mb-0" v-if="hasEinsatzmittel">Einsatzmittel:</h4>
            <ul style="padding-left:20px" v-if="hasEinsatzmittel">
                <li v-for="(em,idx) in fax.Einsatzmittel" :key="idx">{{ em.Einsatzmittel }}
                    <div v-if="not_empty(em.Geraet)" class="ml-3">{{ em.Geraet }}</div>
                </li>
            </ul>
            <h4 class="mb-0">Ausr&uuml;ckeordnung:</h4>
            <ul style="padding-left: 20px">
                <li :key="idx" v-for="(ao,idx) in fax.Ausrueckeordnung">{{ ao }}</li>
            </ul>
        </div>
        <div v-show="karte">
            <v-btn style="position:fixed;top:13px;right:12px;z-index:99" color="primary" @click="karte=false"><v-icon>mdi-reply</v-icon></v-btn>
            <div id="map_canvas"></div>
        </div>
        
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'Alarms',
    data() {
        return {
            fax: {},
            karte:false,
            faxe: [],
            karteninfos:[]
        }
    },
    created() {
        this.getAlarme();
        setInterval(() => {this.getAlarme()},10000)
        this.loadKarteninfos()
    },
    methods: {
        getClass(fax){
            var c = "fax"
            if (fax.aktuellerEinsatz=='1') c= c + " aktuell";
            if (fax.Stichwort.substr(0,3)=='THL') c= c + " thl";
            if (fax.Stichwort.substr(0,1)=='B') c= c + " brand";
            if (fax.Stichwort.substr(0,3)=='ABC') c= c + " abc";
            return c
        },
        async loadKarteninfos(){
            await axios({
                url: process.env.VUE_APP_APIURL+"karteninfos/get",
                method: 'GET' })
            .then(result => {
                this.karteninfos = result.data
            })
        },
        async getAlarme(){
            this.item = {}
            await axios({
                url: process.env.VUE_APP_APIURL+"alarm/getAlarme",
                method: 'GET' })
            .then(result => {
                if (result.status=="200"){
                    this.faxe = result.data
                }
            })
        },
        openAlarm(fax){
            this.fax = fax;
        },
        async show_karte(){
            if (this.fax.lat && this.fax.lon) {
                window.google_maps_initialize(this.fax.lat, this.fax.lon,this.karteninfos);
                this.karte = true
            }else{
                this.karte = false
            }
        },
        not_empty(data){
            return data;
        }
    },
    computed:{
        hasEinsatzmittel(){
            return this.fax.Einsatzmittel && this.fax.Einsatzmittel>0
        },
        faxGeladen(){
            return Object.keys(this.fax).length>0
        }
    }
}
</script>

<style scoped>
  .aktuell{
      background-color: #ac2920 !important;
  }
  #map_canvas{
    height: calc(100vh - 111px);
  }
  .fax{
    border-left:5px solid rgb(66, 66, 66);
  }.brand{
    border-left:5px solid red;
  }
  .thl{
    border-left:5px solid blue;
  }
  .abc{
    border-left:5px solid yellow;
  }
</style>
