<template>
  <geraeteComp />
</template>

<script>

import geraeteComp from '../components/Geraete.vue'

  export default {
    name: 'geraete',
    components: {
        geraeteComp,
    },
  }
</script>
