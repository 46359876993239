<template>
  <SchlauchpflegeComp />
</template>

<script>

import SchlauchpflegeComp from '../components/Schlauchpflege.vue'

  export default {
    name: 'schlauchpflege',
    components: {
        SchlauchpflegeComp,
    },
  }
</script>
