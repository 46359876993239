export default {

    methods:{
        beep(volume,frequency,type,duration){
            var audioCtx = new(window.AudioContext || window.webkitAudioContext)();

            var oscillator = audioCtx.createOscillator();
            var gainNode = audioCtx.createGain();

            oscillator.connect(gainNode);
            gainNode.connect(audioCtx.destination);

            gainNode.gain.value = volume || 1;
            oscillator.frequency.value = frequency || 2500;
            oscillator.type = type || "square";  // sine,square,sawtooth,triangle

            oscillator.start();

            setTimeout(
                function() {
                oscillator.stop();
                },
                duration || 100
            );
        }
    }
};