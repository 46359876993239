<template>
  <v-app >
    <v-navigation-drawer v-model="drawer" app v-if="$store.getters.isAuthenticated">
      <navigation />
      <template v-slot:append>
        <div class="text-caption text-center px-0 mt-auto d-block py-0">Version {{ appversion }}</div>
      </template>

    </v-navigation-drawer>

    <v-app-bar app color="black" dark v-if="$store.getters.isAuthenticated" :src="require('./assets/logo.jpg')">
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          class="ml-14 mt-1"
          max-height="90%"
          width="200px"
        ></v-img>
      </template>
      <v-badge
              color="red"
              offset-x="20"
              offset-y="20"
              class="ma-0 pa-0"
              content=""
              :value="false"
              style="position:relative;left:-14px"
            >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      </v-badge>
      <v-spacer></v-spacer>
      <v-toolbar-title class="hidden-sm-and-down" v-if="this.$store.getters.getProfile.feuerwehr">
        {{ this.$store.getters.getProfile.feuerwehr.feuerwehr }}
      </v-toolbar-title>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view/>
      </v-container>
    </v-main>
    <v-footer
      color="black"
      dark
      app
      class="footer py-3"
    >
      <pwa />
      <span class="white--text">&copy; {{ new Date().getFullYear() }} X-trasoft IT-Services</span>
      <v-spacer></v-spacer>
      <v-badge offset-y="11px" offset-x="-5px" class="mr-4" :color="online ? 'green' : 'red'" dot>{{ this.$store.getters.getProfile.user }}</v-badge>
    </v-footer>
  </v-app>
</template>

<script>

import axios from 'axios';
import navigation from '@/components/menu';
import { USER_REQUEST } from "@/store/actions/user";
import { AUTH_LOGOUT } from "@/store/actions/auth";
import pwa from './components/PWA.vue';
import {version} from './../package'

export default {
  name: 'App',
  components: {
    navigation,
    pwa,
  },
  data: () => ({
    drawer: null,
    logged_in: false,
    online: true,
    appversion: "",
  }),
  created: function() {
    this.appversion = version
    if (this.$store.getters.isAuthenticated) {
      this.$store.dispatch(USER_REQUEST);
    }
    axios.interceptors.response.use(undefined, function (err) {
      return new Promise(function () {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch(AUTH_LOGOUT)
        }
        throw err;
      });
    });
  },
  computed:{

  },
  mounted(){
    window.addEventListener('online', () => this.online = true);
    window.addEventListener('offline', () => this.online = false);
  },
  methods:{

  },
};
</script>

<style  scoped>
  .v-toolbar__content{
    padding-left: 2px !important;
  }
  .footer{
    font-size:0.8rem;
  }
</style>