<template>
  <PAKurzpruefungComp />
</template>

<script>

import PAKurzpruefungComp from '../components/PAKurzpruefung.vue'

  export default {
    name: 'PAKurzpruefung',
    components: {
        PAKurzpruefungComp,
    },
  }
</script>
