<template>
  <Termine />
</template>

<script>

import Termine from '@/components/Termine.vue'

  export default {
    name: 'termine',
    components: {
        Termine,
    },
  }
</script>
