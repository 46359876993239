<template>
  <v-container class="pt-0 px-0">
    <v-subheader class="text-h5 px-0">
      Mannschaft
    </v-subheader>
    <v-subheader class="text-right text-caption">
          <v-switch
            class="mr-5"
            v-model="passive_anzeigen"
            label="Passive"
            dense
          ></v-switch>

          <v-switch
            class="mr-5"
            v-model="jugend_anzeigen"
            label="Jugend"
            dense
          ></v-switch>

          <v-switch
            v-model="aktive_anzeigen"
            label="Aktive"
            dense
          ></v-switch>
  
    </v-subheader>
    <v-text-field
            solo
            v-model="suche"
            @click:clear="clear_suche"
            label="Suchen"
            clearable
            hide-details
            color="white"
          ></v-text-field>
    <v-list three-line>
      <template
          v-for="(item, index) in personen"
          >
        <v-list-group
              v-model="item.active"
              no-action
              class="person"
              color="white"
              :key="index"
              v-if="hasContact(item)"
            >
            <template v-slot:activator>
              <v-list-item class=" px-0">
                <v-list-item-avatar>
                  <v-img :src="item.bild"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    <v-badge
                      :value="item.geburtstagsinfo!=''"
                      offset-y="15"
                      offset-x="-10"
                      dark
                      color="yellow lighten-2"
                      class="birthday_badge"
                      title="Tage bis Geburtstag"
                      :icon="item.geburtstagsinfo==0 ? 'mdi-cake-variant' : undefined"
                      :content="item.geburtstagsinfo && item.geburtstagsinfo>0 ? item.geburtstagsinfo : undefined"
                    >
                      {{ item.name + '&nbsp;' + item.vorname }}
                    </v-badge>
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ item.adresse }}</v-list-item-subtitle>
                  <v-list-item-subtitle>{{ item.plz + '&nbsp;' + item.ort }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          <v-list-item class="pl-4" v-if="hasContact(item)">
            <v-list-item-content class="pt-0">
              <v-row no-gutters>
                <v-col cols="6" class="col-sm-4 col-md-2" v-if="item.telefon_arbeit">
                  <a :href="'tel:'+item.telefon_arbeit" class="text-decoration-none">
                    <v-sheet color="white" rounded light class="mr-1 mb-1 d-flex text-center justify-start">
                      <v-list-item style="min-height:48px !important" class="mx-2 pa-0">
                        <v-list-item-icon class="ma-0 mt-2">
                          <v-avatar size="34" color="primary"><v-icon dark>mdi-account-hard-hat</v-icon></v-avatar>
                        </v-list-item-icon>
                        <v-list-item-content class="text-md-body-2 text-sm-caption text-caption pl-3">{{item.telefon_arbeit}}</v-list-item-content>
                      </v-list-item>
                    </v-sheet>
                  </a>
                </v-col>
                <v-col cols="6" class="col-sm-4 col-md-2" v-if="item.telefon_heim">
                  <a :href="'tel:'+item.telefon_heim" class="text-decoration-none">
                    <v-sheet color="white" rounded light class="mr-1 mb-1 d-flex text-center justify-start">
                      <v-list-item style="min-height:48px !important" class="mx-2 pa-0">
                        <v-list-item-icon class="ma-0 mt-2">
                          <v-avatar size="34" color="primary"><v-icon dark>mdi-home</v-icon></v-avatar>
                        </v-list-item-icon>
                        <v-list-item-content class="text-md-body-2 text-sm-caption text-caption pl-3">{{item.telefon_heim}}</v-list-item-content>
                      </v-list-item>
                    </v-sheet>
                  </a>
                </v-col>
                <v-col cols="6" class="col-sm-4 col-md-2" v-if="item.telefon_mobil">
                  <a :href="'tel:'+item.telefon_mobil" class="text-decoration-none">
                    <v-sheet color="white" rounded light class="mr-1 mb-1 d-flex text-center justify-start">
                      <v-list-item style="min-height:48px !important" class="mx-2 pa-0">
                        <v-list-item-icon class="ma-0 mt-2">
                          <v-avatar size="34" color="primary"><v-icon dark>mdi-cellphone</v-icon></v-avatar>
                        </v-list-item-icon>
                        <v-list-item-content class="text-md-body-2 text-sm-caption text-caption pl-3">{{item.telefon_mobil}}</v-list-item-content>
                      </v-list-item>
                    </v-sheet>
                  </a>
                </v-col>
                <v-col cols="6" class="col-sm-4 col-md-2" v-if="item.telefon_mobil">
                   <a :href="'sms:'+item.telefon_mobil" class="text-decoration-none">
                    <v-sheet color="white" rounded light class="mr-1 mb-1 d-flex text-center justify-start">
                      <v-list-item style="min-height:48px !important" class="mx-2 pa-0">
                        <v-list-item-icon class="ma-0 mt-2">
                          <v-avatar size="34" color="primary"><v-icon dark>mdi-cellphone-message</v-icon></v-avatar>
                        </v-list-item-icon>
                        <v-list-item-content class="text-md-body-2 text-sm-caption text-caption pl-3">{{item.telefon_mobil}}</v-list-item-content>
                      </v-list-item>
                    </v-sheet>
                  </a>
                </v-col>
                <v-col cols="6" class="col-sm-4 col-md-2" v-if="item.whatsapp">
                  <a :href="'https://wa.me/'+item.whatsapp" class="text-decoration-none">
                    <v-sheet color="white" rounded light class="mr-1 mb-1 d-flex text-center justify-start">
                      <v-list-item style="min-height:48px !important" class="mx-2 pa-0">
                        <v-list-item-icon class="ma-0 mt-2">
                          <v-avatar size="34" color="green"><v-icon dark>mdi-whatsapp</v-icon></v-avatar>
                        </v-list-item-icon>
                        <v-list-item-content class="text-md-body-2 text-sm-caption text-caption pl-3">{{item.whatsapp}}</v-list-item-content>
                      </v-list-item>
                    </v-sheet>
                  </a>
                </v-col>
                <v-col cols="6" class="col-sm-4 col-md-2" v-if="item.email">
                  <a :href="'mailto:'+item.email" class="text-decoration-none">
                    <v-sheet color="white" rounded light class="mr-1 mb-1 d-flex text-center justify-start">
                      <v-list-item style="min-height:48px !important" class="mx-2 pa-0">
                        <v-list-item-icon class="ma-0 mt-2">
                          <v-avatar size="34" color="primary"><v-icon dark>mdi-email</v-icon></v-avatar>
                        </v-list-item-icon>
                        <v-list-item-content class="pl-3" :class="item.email.indexOf(',')<0 ? 'text-body-2' : 'text-caption'">{{item.email}}</v-list-item-content>
                      </v-list-item>
                    </v-sheet>
                  </a>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item v-else :key="index" class="person">
          <v-list-item-avatar>
            <v-img :src="item.bild"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ item.name + '&nbsp;' + item.vorname }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.adresse }}</v-list-item-subtitle>
            <v-list-item-subtitle>{{ item.plz + '&nbsp;' + item.ort }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
      <v-snackbar
        v-model="snackbar"
        color="error"
        timeout="5000"
        bottom
         class="snackbar text-center"
        >
        {{ snackbar_text }}
        </v-snackbar>
  </v-container>
</template>

<script>

import axios from 'axios';

  export default {
    name: 'Mannschaft',
    data: () => ({
        items:[],
        suche: "",
        snackbar: false,
        snackbar_text: "Speichern erfolgreich",
        jugend_anzeigen: true,
        aktive_anzeigen: true,
        passive_anzeigen: false,
    }),
    created: function(){
        axios({
              url: process.env.VUE_APP_APIURL+"mannschaft/get",
              method: 'GET' })
          .then(result => {
              if (result.status=="200"){
                  this.snackbar = false;
                  this.items = result.data
              }else{
                  this.snackbar_text = "Datenabruf nicht möglich"
                  this.snackbar = true;
              }
          })
          .catch(() => {
                this.snackbar_text = "Datenabruf nicht möglich"
                this.snackbar = true;
          });
    },
    methods: {
        hasContact : function(item){
          return item.telefon_arbeit || item.telefon_privat || item.telefon_mobil || item.email
        },
        clear_suche: function(){
          this.suche = ""
        }
    },
    computed:{
      personen(){
        //if (!this.suche) return this.items;
        var r = []
        var missing = false;
        var ss = this.suche.toLowerCase().split(" ");
        for (var item of this.items){
          missing = false;
          if (this.suche){
            for(var s of ss){
              if (item.name.toLowerCase().indexOf(s)<0 && item.vorname.toLowerCase().indexOf(s)<0){
                missing = true;
                break;
              }
            }
          }
          if (!missing){
            if (this.aktive_anzeigen && item.isAktiv=='1'){
              if (this.jugend_anzeigen && item.isJugend=='1' || item.isJugend=='0') r.push(item);
            }else if (this.passive_anzeigen && item.isAktiv=='0'){
              if (this.jugend_anzeigen && item.isJugend=='1' || item.isJugend=='0') r.push(item);
            }
          }
        }
        return r;
      },
    }
  }
</script>
<style scoped>
.birthday_badge>>>.v-badge__badge{
  color: #444444 !important;
}
.person{
  border-top:1px solid #444444 !important;
}
.person:first-child{
  border-top:none !important;
}
.kontakt{
  flex:1 1 auto;
  min-height: 48px;;
}
.kontakt .v-card__text{
  line-height: 1rem !important;
  font-size: 0.75rem !important;
}
</style>