import Vue from 'vue'
import VueRouter from 'vue-router'
import Termine from '../views/Termine.vue'
import Alarm from '../views/Alarm.vue'
import Login from '../views/Login.vue'
import Kompressorlogbuch from '../views/Kompressorlogbuch.vue'
import Geraete from '../views/Geraete.vue'
import PAKurzpruefung from '../views/PAKurzpruefung.vue'
import Mannschaft from '../views/Mannschaft.vue'
import Fuehrerscheinkontrolle from '../views/Fuehrerscheinkontrolle.vue'
import Schlauchannahme from '../views/Schlauchannahme.vue'
import Schlauchpflege from '../views/Schlauchpflege.vue'
import settings from '../views/settings.vue'
import store from '../store' // your vuex store 
import { AUTH_LOGOUT } from "@/store/actions/auth";

Vue.use(VueRouter)

const routes = [
  {
    path: '/Login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/Alarm',
    name: 'Alarm',
    component: Alarm,
  },
  {
    path: '/Mannschaft',
    name: 'Mannschaft',
    component: Mannschaft,
  },
  {
    path: '/Geraete',
    name: 'Geraete',
    component: Geraete,
  },
  {
    path: '/Kompressorlogbuch',
    name: 'Kompressorlogbuch',
    component: Kompressorlogbuch,
  },
  {
    path: '/PAKurzpruefung',
    name: 'PAKurzpruefung',
    component: PAKurzpruefung,
  },
  {
    path: '/Fuehrerscheinkontrolle',
    name: 'Fuehrerscheinkontrolle',
    component: Fuehrerscheinkontrolle,
  },
  {
    path: '/Schlauchannahme',
    name: 'Schlauchannahme',
    component: Schlauchannahme,
  },
  {
    path: '/Schlauchpflege',
    name: 'Schlauchpflege',
    component: Schlauchpflege,
  },
  {
    path: '/settings',
    name: 'settings',
    component: settings,
  },
  {
    path: '*',
    name: 'Termine',
    component: Termine,
  }
]

const router = new VueRouter({
  mode: "history",
  routes
})


// https://blog.sqreen.com/authentication-best-practices-vue/
router.beforeEach((to, from, next) => {

  if (to.path == '/logout'){
    store.dispatch(AUTH_LOGOUT)
    next({ name: 'Login' })
  }else if (to.name !== 'Login' && !store.getters.isAuthenticated){
    next({ name: 'Login' })
  }else if (to.name == 'Login' && store.getters.isAuthenticated){
    next({ name: 'Home' }) // Wenn Login und authenticated, goto HOME
  }else{
    next()
  }
})

export default router
