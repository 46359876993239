<template>
    <v-list dense>
        <v-list-item link :to="item.route" v-for="item in menu" :key="item.href" class="pl-0">
          <v-list-item-action class="mr-4">
            <v-icon>{{item.icon}}</v-icon>
          </v-list-item-action>
          <v-badge
              :value="item.badge!=undefined && item.badge!=''"
              color="red"
              offset-y="15"
              offset-x="-1"
              class="birthday_badge mt-0"
              :content="item.badge"
            >
            <v-list-item-content>
              <v-list-item-title>{{item.text}}</v-list-item-title>
            </v-list-item-content>
          </v-badge>
        </v-list-item>
    </v-list>
    
</template>

<script>
  
  import axios from 'axios';
  export default {
    name: 'menue',

    data: () => ({
      menu: [],
    }),
    created: function(){
        axios({
              url: process.env.VUE_APP_APIURL+"menu/get",
              method: 'GET' })
          .then(result => {
              if (result.status=="200"){
                  this.menu = result.data
              }
          });
    },
  }
</script>
