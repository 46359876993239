<template>
      <v-menu
          ref="menu"
          v-model="picker"
          :close-on-content-click="false"
          :return-value.sync="dateIso"
          transition="scale-transition"
          offset-y
          min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
              v-bind="attrs"
              :value="dateFormatted"
              @change="v => dateFormatted = v"
              @keydown.delete="onDelete"
              v-on="on"
              :label="label"
              @blur="setFormatedDate"
              :dense="dense"
              :solo="solo"
              :hide-details="hideDetails"
              :placeholder="placeholder"
              :outlined="outlined"
              :readonly="readonly"
              :disabled="disabled"
              :color="color"
          ></v-text-field>
        </template>
        <v-date-picker first-day-of-week="1" v-if="picker" :value="dateIso" @change="v => dateIso = v" @click:date="$refs.menu.save(dateIso)" locale="de-de" no-title scrollable>
            <v-btn color="primary" @click="picker = false">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="$refs.menu.save(dateIso)">OK</v-btn>
        </v-date-picker>
      </v-menu>
</template>

<script>
  export default {
    name: 'Datefield',
    data: () => ({
      picker:false,
      dateIso:"",
      dateFormatted:"",
    }),
    model: {
      prop: 'value',
      event: 'input'
    },
    props: ['value','label','dense','solo','hideDetails','placeholder','outlined','readonly','disabled','color'],
    watch: {
      dateIso (val) {
          this.dateFormatted = this.formatDate(val);
          this.$emit('input', val);
          this.$emit('change', val);
      },
      value (val) {
        this.dateFormatted = this.formatDate(val);
        this.dateIso = val;
      },
    },
    mounted() {
      this.dateFormatted = this.formatDate(this.value);
    },
    methods: {
      onDelete(){
        this.picker = false;
      },
      setFormatedDate(){
        this.dateFormatted = this.formatDate(this.parseDate(this.dateFormatted))
        var pd = this.parseDate(this.dateFormatted)
        this.$emit('input', pd);
        this.$emit('change', pd);
      },
      formatDate (date) {
          if (!date) return null
          const [year, month, day] = date.split('-')
          return `${day}.${month}.${year}`
      },
      parseDate (date) {
          if (!date) return null

          var m = date.match(/^(\d{2})\.(\d{2})\.(\d{2})$/)
          if (m) return "20"+m[3]+"-"+m[2]+"-"+m[1];

          m = date.match(/^(\d{2})\.(\d{2})\.(\d{4})$/)
          if (m) return m[3]+"-"+m[2]+"-"+m[1];

          m = date.match(/^(\d{6})$/)
          if (m) return "20"+m[1].substring(4, 6)+"-"+m[1].substring(2, 4)+"-"+m[1].substring(0, 2);

          m = date.match(/^(\d{8})$/)
          if (m) return m[1].substring(4, 8)+"-"+m[1].substring(2, 4)+"-"+m[1].substring(0, 2);

          return false;
      },
    },
  }
</script>
