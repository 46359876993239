<template>
  <v-container class="pt-0 pb-0 px-0">
    <v-subheader class="text-h5 px-0">PA-Kurzprüfung</v-subheader>
    <v-overlay
          absolute
          opacity="0.85"
          :value="scanner_enabled"
        >
        <div class="d-flex justify-end">
            <v-btn v-if="scanner_enabled" class="mx-5" small fab dark color="secondary" @click="scanner_enabled = false">
                <v-icon dark>mdi-barcode-off</v-icon>
            </v-btn>
        </div>
        <StreamBarcodeReader
            v-if="scanner_enabled"
            @decode="onDecode"
        ></StreamBarcodeReader>
        </v-overlay>
    
    <v-text-field
        label="Pressluftatmer"
        v-model="pa"
        required
        color="white"
        outlined
        hide-details
        autocomplete="false"
        append-icon="mdi-barcode"
        @focus="field='pa'"
        @blur="field=''"
        @click:append="open_scanner('pa')"
        class="mb-2"
    ></v-text-field>
    <v-text-field
        label="Lungenautomat"
        v-model="la"
        required
        color="white"
        outlined
        autocomplete="false"
        append-icon="mdi-barcode"
        hide-details
        @focus="field='la'"
        @blur="field=''"
        @click:append="open_scanner('la')"
    ></v-text-field>
    <div class="d-flex justify-end mt-4">
        <label for="sichtpruefung" style="position:relative;top:4px">Sichtprüfung</label>
        <v-checkbox hide-details v-model="sichtpruefung" id="sichtpruefung" style="display:inline-block" class="ml-4 mt-0 mb-4" color="white"></v-checkbox>
    </div>
    <div class="d-flex justify-end">
        <label for="dichtpruefung" style="position:relative;top:4px">Dichtprüfung</label>
        <v-checkbox hide-details v-model="dichtpruefung" id="dichtpruefung" style="display:inline-block" class="ml-4 mt-0 mb-4" color="white"></v-checkbox>
    </div>
    <div class="d-flex justify-end">
        <label for="warneinrichtung" style="position:relative;top:4px">Warneinrichtung</label>
        <v-checkbox hide-details v-model="warneinrichtung" id="warneinrichtung" style="display:inline-block" class="ml-4 mt-0 mb-4" color="white"></v-checkbox>
    </div>
    <div class="d-flex justify-end">
        <label for="flaschendruck" style="position:relative;top:4px">Flaschendruck</label>
        <v-checkbox hide-details v-model="flaschendruck" id="flaschendruck" style="display:inline-block" class="ml-4 mt-0 mb-4" color="white"></v-checkbox>
    </div>
    <v-textarea
          v-model="bemerkung"
          outlined
          label="Bemerkung"
          color="white"
          value=""
          hide-details

          @focus="field='bemerkung'"
          @blur="field=''"
        ></v-textarea>
    <v-btn class="mx-0 mt-2" dark block color="primary" :disabled="save_disabled" @click="save">
            Speichern
        </v-btn>
    <v-snackbar
        v-model="snackbar"
        :color="snackbar_color"
        timeout="5000"
        bottom
         class="snackbar text-center"
        >
        {{ snackbar_text }}
        </v-snackbar>
  </v-container>
</template>

<script>

import { StreamBarcodeReader } from "vue-barcode-reader";
import beep from '@/mixins/beep';
import axios from 'axios';
import scanner from '@/mixins/scanner';

  export default {
    name: 'PAKurzpruefung',
    components:{
        StreamBarcodeReader
    },
    mixins: [beep,scanner],
    data: () => ({
        settings:{},
        field: "",
        snackbar: false,
        snackbar_color: "success",
        snackbar_text: "Speichern erfolgreich",
        scanner_enabled: false,
        prev_scan: '',
        num_same_scans: 0,

        pa: '',
        la: '',
        sichtpruefung: false,
        dichtpruefung: false,
        warneinrichtung: false,
        flaschendruck: false,
        bemerkung: '',
    }),
    mounted(){
        var s = localStorage.getItem("settings")
        if (s) this.settings =  JSON.parse(s);
        this.settings.barcodepruefung = this.settings.barcodepruefung || 2;

        //  Barcodescanner
        scanner.setScannerMode(scanner.SCANNER_MODE_TIME);
        this.scanner.on("scan", this.onScan);
    },
    computed:{
        save_disabled:function(){
            return this.pa=="" || this.la=="";
        }
    },
    methods: {
        open_scanner(feld){
            this.prev_scan = "";
            this.field = feld;
            this.scanner_enabled = true;  
        },
        onScan(scandata){
            if(this.field=='pa' || this.field==''){   // PA oder field nicht gesetzt
                this.pa = scandata;
                this.beep(1,2500,"sine",50);
                this.field='la'
            }else if (this.field=='la'){
                this.la = scandata;
                this.beep(1,2500,"sine",50);
                this.field='bemerkung'
            }else if(this.field=='bemerkung'){   // PA oder field nicht gesetzt
                this.bemerkung = scandata;
                this.beep(1,2500,"sine",50);
                this.field=''
            }
        },
        save(){

            axios({
                url: process.env.VUE_APP_APIURL+"pakurzpruefung/save",
                data : {
                    nummer:this.pa,
                    geprueft_mit_la:this.la,
                    sichtpruefung:this.sichtpruefung,
                    dichtpruefung:this.dichtpruefung,
                    warneinrichtung:this.warneinrichtung,
                    flaschendruck:this.flaschendruck,
                    bemerkung:this.bemerkung,
                }, 
                method: 'POST' })
            .then(result => {
                if (result.status=="200"){
                    this.snackbar_text = "Speichern erfolgreich"
                    this.snackbar_color = "success"
                    this.snackbar = true;
                    this.resetForm();
                }else{
                    this.snackbar_text = "Speichern fehlerhaft"
                    this.snackbar_color = "error"
                    this.snackbar = true;
                }
            })
            .catch(() => {
                    this.snackbar_text = "Speichern fehlerhaft"
                    this.snackbar_color = "error"
                    this.snackbar = true;
            });
        },
        resetForm(){
            this.pa = ''
            this.la = ''
            this.sichtpruefung = false
            this.dichtpruefung = false
            this.warneinrichtung = false
            this.flaschendruck = false
            this.bemerkung = ''
        },
        onDecode (result) {
            if (result!=this.prev_scan){
                this.num_same_scans = 0;
            }else{
                this.num_same_scans++;
                if (this.num_same_scans>=this.settings.barcodepruefung){
                    if (this.field=='pa'){
                        this.pa = result;
                        this.beep(1,2500,"sine",50);
                        this.scanner_enabled = false;
                    }else if (this.field=='la'){
                        this.la = result;
                        this.beep(1,2500,"sine",50);
                        this.scanner_enabled = false;
                    }
                }else{
                    this.beep(0.5,3500,"sine",10);
                }
            }
            this.prev_scan = result;
        }
    }
  }
</script>
<style scoped>
    .snackbar{
        text-align: center;
    }
    .v-input__slot {
    align-items: right;
    justify-content:end
    }
</style>