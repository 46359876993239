<template>
  <SchlauchannahmeComp />
</template>

<script>

import SchlauchannahmeComp from '../components/Schlauchannahme.vue'

  export default {
    name: 'schlauchannahme',
    components: {
        SchlauchannahmeComp,
    },
  }
</script>
