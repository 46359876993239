<template>
  <Mannschaft />
</template>

<script>

import Mannschaft from '@/components/Mannschaft.vue'

  export default {
    name: 'mannschaft',
    components: {
        Mannschaft,
    },
  }
</script>
